<template>
  <header>
    <!-- Background image -->
    <div
      class="bg-image"
      :style="
        {
          backgroundImage: `url(${iconUrl})`
        }
      "
      rel="preload"
    >
      <div class="mask" style="background-color: rgba(0, 0, 0, 0.6);">
        <span class="site-name top">OFFBIKE</span>
        <span class="site-description bottom">Riparazione ricambi e restauro</span>
      </div>
    </div>
    <!-- Background image -->
  </header>
</template>

<script>
  export default {
    components: {},
    computed: {
      iconUrl () {
        return require('@/assets/background.jpg')
      }
    }
  };
</script>

<style scoped>
.bg-image {
  display: none;
  max-height: 400px;
  width: 100%;
}
.site-name {
  color: var(--mdb-orange);
  font-size: 40px;
  font-weight: bold;
  left: 10px;
}

.site-description {
  color: var(--mdb-orange);
  font-size: 30px;
  left: 10px;
}
.bottom {
    bottom: 0;
    position: absolute;
}
.top {
    top: 0;
    position: absolute;
}
@media screen and (min-height: 480px) {
  .bg-image {
    height: 500px;
    display: flex;
  }
}
</style>
