<template>
  <Header></Header>
  <ContactForm></ContactForm>
  <Footer></Footer>
</template>

<script>
import Header from './components/header/HeaderBase.vue'
import Footer from './components/footer/FooterBase.vue'
import ContactForm from './components/ContactForm.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    ContactForm
}
}
</script>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
</style>
