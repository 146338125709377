<template>
	<section class="text-center">
		<div class="mt-3">
			<div class="col-lg-12">
				<iframe 
					id="maps"
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3048.779670124647!2d18.17328831448293!3d40.16945907856641!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13442269d2a51245%3A0xf6c0cb4d8732d62!2sRiparazioni%20Bici%20Offbike!5e0!3m2!1sit!2sit!4v1675182559843!5m2!1sit!2sit" 
					height="450" 
					style="border:0;" 
					allowfullscreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
				</iframe>
			</div>
		</div>
	</section>
</template>

<style scoped>
#maps {
	width: 90%;
}
</style>